/* Provide sufficient contrast against white background */
body, html {
  height: 100%
}
#root {
  height: 100%;
}
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.MuiDataGrid-root .MuiDataGrid-cell{
  white-space: normal !important;
  word-wrap: break-word !important;}